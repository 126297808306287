<template>
  <div class="row">
    <div class="col-md-12">
      <form @submit.prevent="filterResult(null)">
        <div class="row py-3 px-4" id="filter-card">
          <div class="col-lg-12 col-xl-12 px-0">
            <div class="row align-items-end py-0 my-0 mx-0">
              <div class="col-lg-11 col-md-6 col-sm-12 my-2 row px-0 mx-0">
                <input-filter
                  :helper-text="$t('general.search')"
                  title="general.search"
                  :placeholder="$t('general.search_filter')"
                  :model.sync="search"
                ></input-filter>
              </div>
              <div
                class="col-xl-1 my-lg-1 col-md-2 col-sm-12 mt-3 d-flex align-items-center justify-content-end m-0 px-0 row"
                style="margin: 0 !important"
              >
                <div class="col-12 pr-0">
                  <button
                    @click="filterResult(null)"
                    class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold py-1"
                  >
                    {{ $t("general.filter") }}
                  </button>
                </div>
                <div class="col-12 pr-0">
                  <button
                    @click="resetFilters"
                    class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold mt-2 py-1"
                  >
                    {{ $t("general.clear") }}
                  </button>
                </div>
              </div>
              <div
                class="col-xl-1 my-lg-1 col-md-2 col-sm-12 mt-3 d-flex align-items-center justify-content-end m-0 px-0 row"
              ></div>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="col-md-12">
      <dashboard-box :title="$t('general.fabrics')">
        <template v-slot:preview>
          <data-table
            tableVariant="''"
            :hover="false"
            :outlined="false"
            :striped="false"
            :no-border-collapse="true"
            :borderless="true"
            :bordered="false"
            @onClickDelete="onClickDelete"
            @onClickEdit="onClickEdit"
            @hitBottom="onButtonHit"
            :items="itemsProxy"
            :fields="fields"
            :page.sync="page"
            :perPage.sync="per_page"
          ></data-table>
          <div class="d-flex justify-content-end items-end float-right mt-0">
            <div
              class="w-60px h-60px"
              @click="$router.push({ name: 'management.fabric-permission.create' })"
            >
              <span
                v-html="
                  getIconByKey('icons.waybill.waybill_entry', {
                    class: 'w-60px h-60px d-inline-block object-fill cursor-pointer',
                  })
                "
              >
              </span>
            </div>
          </div>
        </template>
      </dashboard-box>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapMutations, mapGetters } from "vuex";
import moment from "moment";

// COMPONENTS
import DashboardBox from "@/assets/components/DashboardBox";
import DataTable from "@/assets/components/dataTable/DataTable";
import InputFilter from "@/assets/components/filters/InputFilter";
import SelectFilter from "@/assets/components/filters/SelectFilter";
import DateFilter from "@/assets/components/filters/DateFilter";

import baseModule, {
  BASE_URL,
  ERROR,
  SET_ITEMS,
  ITEMS,
  LOADING,
  MODULE_NAME,
  DELETE_ITEM,
  SUCCESS,
  HANDLE_INFINITE_SCROLL,
  CHANGE_STATUS_BY_ID,
} from "@/core/services/store/fabric-permission/fabricPermission.module";

import { LARAVEL_DATE_FORMAT, MOMENT_SYSTEM_DATE_FORMAT } from "@/core/config/constant";
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import store from "@/core/services/store";

const _MODULE_NAME = MODULE_NAME;

export default {
  name: "FabricIndex",
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }

    registerStoreModule(_MODULE_NAME, baseModule);
  },
  components: {
    CustomMultiSelect,
    SelectFilter,
    InputFilter,
    DataTable,
    DashboardBox,
    DateFilter,
  },
  computed: {
    error() {
      return store.getters[_MODULE_NAME + "/" + ERROR];
    },
    loading() {
      return store.getters[_MODULE_NAME + "/" + LOADING];
    },
    success() {
      return store.getters[_MODULE_NAME + "/" + SUCCESS];
    },
    items() {
      return store.getters[_MODULE_NAME + "/" + ITEMS];
    },
    itemsProxy: {
      get() {
        let results = [];
        let items = this.items;

        if (items && items.data) {
          items.data.forEach((item) => {
            let formatedItem = this.convertArrayToObjectByKey(
              item.fabrics,
              "id",
              "name"
            );
            const values = Object.values(formatedItem);
            results.push({
              id: item.id,
              fabrics: values.join(", "),
              role: item.role.name,
            });
          });
        }
        return results;
      },
      set(value) {},
    },
    filters() {
      return {
        per_page: this.per_page,
        page: this.page,
        search: this.search
      };
    },
  },
  data() {
    return {
      search: "",
      state: null,
      page: 1,
      per_page: 25,
      fields: [
        {
          key: "id",
          label: this.$t("general.id"),
          sortable: true,
          class: "text-center border border-top-0 border-right-0 font-size-lg ",
        },
        {
          key: "fabrics",
          label: this.$t("general.fabric"),
          sortable: true,
          class: "text-center border border-top-0 border-right-0 font-size-lg w-50",
        },
        {
          key: "role",
          label: this.$t("scope.Role"),
          sortable: true,
          class: "text-center border border-top-0 border-right-0 font-size-lg w-20",
        },
        {
          key: "actions",
          label: this.$t("general.actions"),
          sortable: false,
          class:
            "text-center border border-top-0 border-right-0 font-weight-bolder font-size-lg",
        },
      ],
      selectedLanguageId: null,
      languages: [],
    };
  },
  methods: {
    onButtonHit($state) {
      if (this.loading) {
        return;
      }
      this.state = $state;
      this.$store
        .dispatch(_MODULE_NAME + "/" + HANDLE_INFINITE_SCROLL, {
          filters: this.filters,
          url: BASE_URL,
          $state: $state,
        })
        .then((result) => {
          if (result.status && $state) {
            $state.loaded();
          } else {
            if ("end" in result && $state) {
              $state.complete();
            }
          }
        });
    },
    filterResult() {
      this.page = 1;
      this.filters.page = 1;
      let state = this.state;
      this.onButtonHit();
      setTimeout(() => {
        if (state) {
          state.reset();
        }
      }, 100);
    },
    resetFilters() {
      this.search = "";
    },
    onClickDelete(id) {
      let self = this;
      this.sweetAlertConfirm(this.$t("general.are_you_sure")).then((result) => {
        if (result) {
          let payload = {
            url: BASE_URL + "/" + id,
            id: id,
          };
          self.$store.dispatch(_MODULE_NAME + "/" + DELETE_ITEM, payload);
        }
      });
    },
    onClickEdit(id) {
      this.$router.push({ name: "management.fabric-permission.edit", params: { id: id } });
    },
  },
  mounted() {
    this.showSuccessNotification();
    this.showErrorNotification();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("general.dashboard"), route: "dashboard" },
      { title: this.$t("menu.management.expense_permissions") },
    ]);

    this.$store.commit(_MODULE_NAME + "/" + SET_ITEMS, {});

    this.languages = this.convertArrayToObjectByKey(this.systemLanguages, "id", "name");
  },
  watch: {
    success: function (newValue, oldValue) {
      if (newValue) {
        let self = this;
        setTimeout(() => {
          self.sweetAlertSuccess(this.$t("general.successfully_saved"));
        }, 1000);
      }
    },
  },
};
</script>

<style>
*:focus {
  outline: none;
}

#dashboard-card-body {
  padding-bottom: 5px !important;
}
</style>
